import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { states } from '../../states';
import { AuthContext } from '../../context/AuthContext';
import { createAssetService } from '../../services/assetService';

const AddAsset = ({ open, handleClose }) => {
  const { addAsset } = useContext(AuthContext);

  const [type, setType] = useState('');
  const [bookValue, setBookValue] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [modelYear, setModelYear] = useState('');
  const [usage, setUsage] = useState('');
  const [condition, setCondition] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const user = JSON.parse(sessionStorage.getItem('currentUser'));

  console.log('user:', user);

  const validateInputs = () => {
    let isValid = true;

    if (!type) isValid = false;
    if (!bookValue || isNaN(bookValue) || parseFloat(bookValue) <= 0) isValid = false;
    if (!manufacturer) isValid = false;
    if (!model) isValid = false;
    if (!modelYear || isNaN(modelYear) || parseInt(modelYear, 10) <= 0) isValid = false;
    if (!usage || isNaN(usage) || parseInt(usage, 10) < 0) isValid = false;
    if (!condition) isValid = false;
    if (!country) isValid = false;
    if (country === 'US' && !state) isValid = false;

    return isValid;
  };
  const handleAddAsset = () => {
    const newAsset = {
      name: `${manufacturer} ${model}`,
      type,
      bookValue: parseFloat(bookValue),
      manufacturer,
      model,
      modelYear,
      usage: parseInt(usage),
      condition,
      country,
      state,
      estimatedDepreciation: parseFloat(bookValue) * 0.06,
      fairMarketValue: parseFloat(bookValue) * 0.94, // time added also needs to be recorded for sorting purposes
    };

    if (!validateInputs()) {
      return;
    }

    createAssetService({
      userId: user.id,
      type: newAsset.type,
      bookValue: newAsset.bookValue,
      manufacturer: newAsset.manufacturer,
      model: newAsset.model,
      modelYear: newAsset.modelYear,
      usage: newAsset.usage,
      condition: newAsset.condition,
      country: newAsset.country,
      state: newAsset.state,
    })
      .then((response) => response.json())
      .catch(error => {
        console.error('Error creating asset:', error);
      });

    addAsset(newAsset);
    handleClose();
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    if (e.target.value !== 'US') {
      setState('');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Add New Asset</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>Enter your asset information</DialogContentText>

        <TextField
          select
          required
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          fullWidth
        >
          <MenuItem value="Equipment">Equipment</MenuItem>
          <MenuItem value="Vehicle">Vehicle</MenuItem>
        </TextField>

        <TextField
          required
          label="Initial Book Value"
          type="number"
          value={bookValue}
          onChange={(e) => setBookValue(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Manufacturer"
          value={manufacturer}
          onChange={(e) => setManufacturer(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Model Year"
          type="number"
          value={modelYear}
          onChange={(e) => setModelYear(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Usage (in hours or miles)"
          type="number"
          value={usage}
          onChange={(e) => setUsage(e.target.value)}
          fullWidth
        />

        <TextField
          select
          required
          label="Condition"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          fullWidth
        >
          <MenuItem value="Excellent">Excellent</MenuItem>
          <MenuItem value="Very Good">Very Good</MenuItem>
          <MenuItem value="Good">Good</MenuItem>
          <MenuItem value="Fair">Fair</MenuItem>
          <MenuItem value="Poor">Poor</MenuItem>
        </TextField>

        <TextField
          select
          required
          label="Country"
          value={country}
          onChange={handleCountryChange}
          fullWidth
        >
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="MX">Mexico</MenuItem>
        </TextField>

        {country === 'US' && (
          <TextField
            select
            required
            label="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
          >
            <MenuItem value="">- Select -</MenuItem>
            {states.map((state) => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </DialogContent>

      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button color='success' onClick={handleClose}>Cancel</Button>
        <Button color='success' variant="contained" type="submit" onClick={handleAddAsset}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddAsset.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AddAsset;
