import React, { useContext, useState } from 'react';

import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import AssetTableRow from './AssetTableRow';
import { AuthContext } from '../../context/AuthContext';
import { Typography } from '@mui/material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const AssetTable = () => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(false);

  var assetList = user && user.assets ? [...user.assets] : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="asset table" size='small'>
          <TableHead className='white-text-black-background'>
            <TableRow>
              <TableCell className='empty-table-cell'></TableCell>
              <TableCell className='white-text-black-background'>Asset Name</TableCell>
              <TableCell
                className='white-text-black-background'
                align="right"
                sortDirection={orderBy === 'bookValue' ? order : false}
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'bookValue'}
                  direction={orderBy === 'bookValue' ? order : 'asc'}
                  onClick={handleRequestSort('bookValue')}
                >
                  {'Book Value'}
                  {orderBy === 'bookValue' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                className='white-text-black-background'
                align="right"
                sortDirection={orderBy === 'estimatedDepreciation' ? order : false}
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'estimatedDepreciation'}
                  direction={orderBy === 'estimatedDepreciation' ? order : 'asc'}
                  onClick={handleRequestSort('estimatedDepreciation')}
                >
                  {'Estimated Depreciation'}
                  {orderBy === 'estimatedDepreciation' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                className='white-text-black-background'
                align="right"
                sortDirection={orderBy === 'fairMarketValue' ? order : false}
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'fairMarketValue'}
                  direction={orderBy === 'fairMarketValue' ? order : 'asc'}
                  onClick={handleRequestSort('fairMarketValue')}
                >
                  {'Fair Market Value'}
                  {orderBy === 'fairMarketValue' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>


            </TableRow>

          </TableHead>
          <TableBody>
            {assetList ? assetList
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <AssetTableRow key={row.id} row={row} />
              )) : (<Typography>Empty</Typography>)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        height={'46px'}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={assetList ? assetList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ backgroundColor: '#F5F5F5', cursor: 'hover' }}
      />
    </Paper>
  );
}

export default AssetTable;