import React, { useState, useRef } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'; // Import for tooltips
import { states } from './states'; // Import the states array from states.js
import { API_KEY_ENDPOINT1, API_KEY_ENDPOINT2 } from './config'; // Import the API keys from config.js

const ValueFinder = () => {
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [modelYear, setModelYear] = useState('');
  const [usage, setUsage] = useState('');
  const [assetCondition, setAssetCondition] = useState(''); // Empty for - Select -
  const [country, setCountry] = useState(''); // Empty for - Select -
  const [state, setState] = useState(''); // Empty for - Select -
  const [apiResponse, setApiResponse] = useState([]);
  const [valueApiData, setValueApiData] = useState(null);
  const [selectedApi, setSelectedApi] = useState(''); // Empty for - Select -
  const [searchHistory, setSearchHistory] = useState([]); // To store search history
  const [isSubmitting, setIsSubmitting] = useState(false); // To disable submit after submission
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // To track if form has been submitted

  // Ref for asset details section
  const assetDetailsRef = useRef(null);

  const apiUrls = {
    endpoint1: "https://equipmentwatchapi.com/v1/taxonomy/models",
    endpoint2: "https://pricedigestsapi.com/v1/taxonomy/configurations/", // Updated vehicle configurations endpoint
    equipmentValueEndpoint: "https://equipmentwatchapi.com/v1/values/value", // Equipment value endpoint
    vehicleValueEndpoint: "https://pricedigestsapi.com/v1/values/value/", // Vehicle value endpoint
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    clearResults();
    if (e.target.value !== 'US') {
      setState('');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Value not Available';
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatCurrency = (value) => {
    if (value == null) return 'Value not Available';
    return `$${Number(value).toLocaleString()}`;
  };

  const renderTooltip = (tooltipText) => (
    <Tooltip id="tooltip">{tooltipText}</Tooltip>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsFormSubmitted(true); // Mark the form as submitted when submitting
  
    const apiUrl = `${apiUrls[selectedApi]}?model=${encodeURIComponent(model)}&manufacturer=${encodeURIComponent(manufacturer)}&modelYear=${encodeURIComponent(modelYear)}&usage=${encodeURIComponent(usage)}&assetCondition=${encodeURIComponent(assetCondition)}&country=${encodeURIComponent(country)}&state=${encodeURIComponent(state)}`;
  
    const apiKey = selectedApi === 'endpoint1' ? API_KEY_ENDPOINT1 : API_KEY_ENDPOINT2;
  
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      });
      const data = await response.json();
  
      // Filter to include items where any of the following fields are true:
      // eqwValues, eqwSpecs, eqwRental, eqwVerification, eqwMarketData, eqwCost
      const filteredData = selectedApi === 'endpoint1'
        ? data.filter(item =>
            item.eqwValues ||
            item.eqwSpecs ||
            item.eqwRental ||
            item.eqwVerification ||
            item.eqwMarketData ||
            item.eqwCost
          )
        : data;
  
      setApiResponse(filteredData);
  
      // Store search criteria, results, and asset details in history
      setSearchHistory([
        ...searchHistory,
        {
          manufacturer,
          model,
          modelYear,
          usage,
          assetCondition,
          country,
          state,
          selectedApi,
          apiResponse: filteredData, // Store API response in history
          valueApiData, // Store asset details
        },
      ]);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // const submitValueRequest = async (modelId, configurationId = null) => {
  //   const isVehicle = selectedApi === 'endpoint2';
  //   let valueUrl = '';
  //   let apiKey = '';
  
  //   // First, make the existing value endpoint request
  //   if (isVehicle) {
  //     valueUrl = `${apiUrls.vehicleValueEndpoint}?configurationId=${configurationId}&usage=${usage}&condition=${encodeURIComponent(assetCondition)}&country=${country}&state=${state}`;
  //     apiKey = API_KEY_ENDPOINT2;
  //   } else {
  //     valueUrl = `${apiUrls.equipmentValueEndpoint}?modelId=${modelId}&year=${modelYear}&usage=${usage}&condition=${encodeURIComponent(assetCondition)}&country=${encodeURIComponent(country)}&region=${state}`;
  //     apiKey = API_KEY_ENDPOINT1;
  //   }
  
  //   try {
  //     // Fetch the value data from the existing endpoint
  //     const valueResponse = await fetch(valueUrl, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-api-key': apiKey,
  //       },
  //     });
  //     const valueData = await valueResponse.json();
  
  //     if (!isVehicle) {
  //       // If it's equipment, make an additional request to the ICR endpoint for salvage value
  //       const icrUrl = `https://equipmentwatchapi.com/v1/cost/icr?modelId=${modelId}&configurationSequence=1`;
        
  //       const icrResponse = await fetch(icrUrl, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-api-key': API_KEY_ENDPOINT1,
  //         },
  //       });
  //       const icrData = await icrResponse.json();
  
  //       // Extract salvage value from icrFactors
  //       const salvageValue = icrData.icrFactors?.salvage ? parseFloat(icrData.icrFactors.salvage).toFixed(3) : 'Value not Available';
        
  //       // Combine the value data with the salvage value
  //       setValueApiData({
  //         ...valueData,
  //         salvage: salvageValue, // Add salvage value
  //       });
  //     } else {
  //       // For vehicles, just use the value data
  //       setValueApiData(valueData);
  //     }
  
  //     // Scroll to asset details section
  //     assetDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
  
  //     // Update search history
  //     const updatedHistory = [...searchHistory];
  //     updatedHistory[updatedHistory.length - 1].valueApiData = valueData;
  //     setSearchHistory(updatedHistory);
  
  //   } catch (error) {
  //     console.error('Error fetching value data:', error);
  //     setValueApiData({ errorCode: -1, message: 'Error fetching value data.' });
  //   }
  // };

  const submitValueRequest = async (modelId, configurationId = null) => {
    const isVehicle = selectedApi === 'endpoint2';
    let valueUrl = '';
  
    if (isVehicle) {
      // Use vehicle API
      valueUrl = `${apiUrls.vehicleValueEndpoint}?configurationId=${configurationId}&usage=${usage}&condition=${encodeURIComponent(assetCondition)}&country=${country}&state=${state}`;
    } else {
      // Use equipment API
      valueUrl = `${apiUrls.equipmentValueEndpoint}?modelId=${modelId}&year=${modelYear}&usage=${usage}&condition=${encodeURIComponent(assetCondition)}&country=${country}&region=${state}`;
    }
  
    try {
      // Fetch value data from the existing API
      const valueResponse = await fetch(valueUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': isVehicle ? API_KEY_ENDPOINT2 : API_KEY_ENDPOINT1,
        },
      });
      const valueData = await valueResponse.json();
  
      let salvageValue = 'Value not Available';
  
      if (!isVehicle) {
        // Fetch the salvage value from ICR API for equipment
        const icrUrl = `https://equipmentwatchapi.com/v1/cost/icr?modelId=${modelId}&configurationSequence=1`;
  
        const icrResponse = await fetch(icrUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY_ENDPOINT1,
          },
        });
        const icrData = await icrResponse.json();
  
        // Extract salvage value from icrFactors
        salvageValue = icrData.icrFactors?.salvage ? parseFloat(icrData.icrFactors.salvage).toFixed(3) : 'Value not Available';
  
        // Combine value data with salvage value
        setValueApiData({
          ...valueData,
          salvage: salvageValue, // Add salvage value
        });
      } else {
        // For vehicles, only use value data
        setValueApiData(valueData);
      }
  
      // Scroll to asset details section
      assetDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
  
      // Update search history and include the salvage value if it's equipment
      const updatedHistory = [...searchHistory];
      updatedHistory[updatedHistory.length - 1] = {
        ...updatedHistory[updatedHistory.length - 1],
        valueApiData: valueData,
        salvage: salvageValue, // Cache salvage value in history
      };
      setSearchHistory(updatedHistory);
  
    } catch (error) {
      console.error('Error fetching value data:', error);
      setValueApiData({ errorCode: -1, message: 'Error fetching value data.' });
    }
  };
  
  
    // Clear form when the type dropdown is changed
    const handleTypeChange = (e) => {
      // setSelectedApi(e.target.value);
      setManufacturer('');
      setModel('');
      setModelYear('');
      setUsage('');
      setAssetCondition('');
      setCountry('');
      setState('');
      clearResults(); // Clear previous results and asset details
    };
  
  // Clear results and asset details when form is changed
  const clearResults = () => {
    setApiResponse([]);
    setValueApiData(null);
  };


// Repopulate form and display stored results when clicking a search history item
  const handleHistoryClick = (entry) => {
    setManufacturer(entry.manufacturer);
    setModel(entry.model);
    setModelYear(entry.modelYear);
    setUsage(entry.usage);
    setAssetCondition(entry.assetCondition);
    setCountry(entry.country);
    setState(entry.state);
    setSelectedApi(entry.selectedApi);
    setApiResponse(entry.apiResponse); // Display stored API results
    setValueApiData({
      ...entry.valueApiData,
      salvage: entry.salvage, // Repopulate salvage value
    }); // Display stored asset details
  };
  

  return (
    <div className="container-fluid">
      {/* Header row with logo and Value Finder text */}
      <header className="d-flex align-items-center" style={{ height: '50px', padding: '0 15px', borderBottom: '1px solid #ddd' }}>
        <h1 className="ml-3" style={{ fontSize: '24px', marginLeft: '15px' }}>Value Finder</h1>
      </header>

      <div className="row">
        {/* Left pane to show search history */}
        <div className="col-md-3">
          <h4 className="text-center my-2" style={{ fontSize: '18px' }}>History</h4>
          <ul className="list-group" style={{ height: '100%', overflowY: 'auto' }}>
            {searchHistory.length === 0 ? (
              <li className="list-group-item">No history yet</li>
            ) : (
              searchHistory.map((entry, index) => (
                <li
                  key={index}
                  className="list-group-item mb-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleHistoryClick(entry)} // Repopulate form on click
                >
                  <strong>Manufacturer:</strong> {entry.manufacturer}<br />
                  <strong>Model:</strong> {entry.model}<br />
                  <strong>Year:</strong> {entry.modelYear}
                </li>
              ))
            )}
          </ul>
        </div>

        {/* Main form and results section */}
        <div className="col-md-9">
          <div className="row">
          <h4 className="text-center my-2" style={{ fontSize: '18px' }}>Search Criteria</h4>
          </div>

          <form onSubmit={handleSubmit} className="mb-4">
            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Type: <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <select
                  className="form-control"
                  required
                  value={selectedApi}
                  onChange={(e) => {
                    setSelectedApi(e.target.value);
                    clearResults();
                    handleTypeChange();
                  }}
                >
                  <option value="">- Select -</option>
                  <option value="endpoint1">Equipment</option>
                  <option value="endpoint2">Vehicle</option>
                </select>
              </div>
            </div>

            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Manufacturer: <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={manufacturer}
                  onChange={(e) => {
                    setManufacturer(e.target.value);
                    clearResults();
                  }}
                />
              </div>
            </div>

            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Model: <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value);
                    clearResults();
                  }}
                />
              </div>
            </div>

            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Model Year: <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={modelYear}
                  onChange={(e) => {
                    setModelYear(e.target.value);
                    clearResults();
                  }}
                />
              </div>
            </div>

            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Usage: (in hours or miles): <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={usage}
                  onChange={(e) => {
                    setUsage(e.target.value);
                    clearResults();
                  }}
                />
              </div>
            </div>

            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Asset Condition: <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <select
                  className="form-control"
                  required
                  value={assetCondition}
                  onChange={(e) => {
                    setAssetCondition(e.target.value);
                    clearResults();
                  }}
                >
                  <option value="">- Select -</option>
                  <option>Excellent</option>
                  <option>Very Good</option>
                  <option>Good</option>
                  <option>Fair</option>
                  <option>Poor</option>
                </select>
              </div>
            </div>

            <div className="form-group row mb-3">
              <label className="col-sm-2 col-form-label">Country: <span style={{ color: 'red' }}>*</span></label>
              <div className="col-sm-10">
                <select
                  className="form-control"
                  required
                  value={country}
                  onChange={handleCountryChange}
                >
                  <option value="">- Select -</option>
                  <option value="US">United States</option>
                  <option value="MX">Mexico</option>
                </select>
              </div>
            </div>

            {country === 'US' && (
              <div className="form-group row mb-3">
                <label className="col-sm-2 col-form-label">State <span style={{ color: 'red' }}>*</span>:</label>
                <div className="col-sm-10">
                  <select
                    className="form-control"
                    required
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      clearResults();
                    }}
                  >
                    <option value="">- Select -</option>
                    {states.map((state) => (
                      <option key={state.abbreviation} value={state.abbreviation}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {/* Align Submit button to the right */}
            <div className="text-right">
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </form>

          {apiResponse.length > 0 && (
            <div className="response-area">
              <h2 className="text-center my-4">Results</h2>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Model Name</th>
                    <th>Manufacturer Name</th>
                    {selectedApi === 'endpoint2' && <th>VIN Model Number</th>}
                    <th>Classification</th>
                    <th>Category</th>
                    <th>Subtype</th>
                    <th>Size Class</th>
                    {selectedApi !== 'endpoint2' && <>
                      <th>Date Introduced</th>
                      <th>Date Discontinued</th>
                    </>}
                  </tr>
                </thead>
                <tbody>
                  {apiResponse.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={() => submitValueRequest(item.modelId, item.configurationId)}
                        >
                          Details
                        </button>
                      </td>
                      <td>{item.modelName}</td>
                      <td>{item.manufacturerName}</td>
                      {selectedApi === 'endpoint2' && <td>{item.vinModelNumber || 'Value not Available'}</td>}
                      <td>{item.classificationName}</td>
                      <td>{item.categoryName}</td>
                      <td>{item.subtypeName}</td>
                      <td>{`${item.sizeClassName} (${item.sizeClassMin} - ${item.sizeClassMax} ${item.sizeClassUom})`}</td>
                      {selectedApi !== 'endpoint2' && <>
                        <td>{formatDate(item.dateIntroduced)}</td>
                        <td>{formatDate(item.dateDiscontinued)}</td>
                      </>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Show "No Results Found" only after form is submitted and if no items are found */}
          {isFormSubmitted && apiResponse.length === 0 && (
            <div className="mt-4">
              <h2 className="text-center">No Results Found</h2>
              <p className="text-center">There are no items that match the criteria.</p>
            </div>
          )}

          {valueApiData && (
            <div ref={assetDetailsRef} className="asset-details mt-4">
              <h2 className="text-underline">Asset Details</h2>
              {selectedApi === 'endpoint2' ? (
                <>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted low value.')}>
                    <p><strong>Unadjusted Low:</strong> {formatCurrency(valueApiData.unadjustedLow)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted high value.')}>
                    <p><strong>Unadjusted High:</strong> {formatCurrency(valueApiData.unadjustedHigh)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted finance value.')}>
                    <p><strong>Unadjusted Finance:</strong> {formatCurrency(valueApiData.unadjustedFinance)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted retail value.')}>
                    <p><strong>Unadjusted Retail:</strong> {formatCurrency(valueApiData.unadjustedRetail)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted wholesale value.')}>
                    <p><strong>Unadjusted Wholesale:</strong> {formatCurrency(valueApiData.unadjustedWholesale)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted trade-in value.')}>
                    <p><strong>Unadjusted Trade In:</strong> {formatCurrency(valueApiData.unadjustedTradeIn)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted high value.')}>
                    <p><strong>Adjusted High:</strong> {formatCurrency(valueApiData.adjustedHigh)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted low value.')}>
                    <p><strong>Adjusted Low:</strong> {formatCurrency(valueApiData.adjustedLow)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted finance value.')}>
                    <p><strong>Adjusted Finance:</strong> {formatCurrency(valueApiData.adjustedFinance)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted retail value.')}>
                    <p><strong>Adjusted Retail:</strong> {formatCurrency(valueApiData.adjustedRetail)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted wholesale value.')}>
                    <p><strong>Adjusted Wholesale:</strong> {formatCurrency(valueApiData.adjustedWholesale)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted trade-in value.')}>
                    <p><strong>Adjusted Trade In:</strong> {formatCurrency(valueApiData.adjustedTradeIn)}</p>
                  </OverlayTrigger>
                </>
              ) : (
                <div>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted fair market value.')}>
                    <p><strong>Unadjusted Fair Market Value:</strong> {formatCurrency(valueApiData.unadjustedFmv)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted orderly liquidation value.')}>
                    <p><strong>Unadjusted Orderly Liquidation Value:</strong> {formatCurrency(valueApiData.unadjustedOlv)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the unadjusted forced liquidation value.')}>
                    <p><strong>Unadjusted Forced Liquidation Value (Salvage):</strong> {formatCurrency(valueApiData.unadjustedFlv)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted fair market value.')}>
                    <p><strong>Adjusted Fair Market Value:</strong> {formatCurrency(valueApiData.adjustedFmv)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted orderly liquidation value.')}>
                    <p><strong>Adjusted Orderly Liquidation Value:</strong> {formatCurrency(valueApiData.adjustedOlv)}</p>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={renderTooltip('This is the adjusted forced liquidation value.')}>
                    <p><strong>Adjusted Forced Liquidation Value (Salvage):</strong> {formatCurrency(valueApiData.adjustedFlv)}</p>
                  </OverlayTrigger>
                  {/* Salvage value display */}
        <OverlayTrigger placement="top" overlay={renderTooltip('This is the salvage value.')}>
          <p><strong>Salvage:</strong> {valueApiData.salvage}</p>
        </OverlayTrigger>
                </div>
              )}
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default ValueFinder;
