import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { DASHBOARD_URL, ASSETS_URL, SETTINGS_URL, VALUE_FINDER_URL } from '../constants/pathConstants';

import Dashboard from './Dashboard';
import Header from './Header';
import ValueFinder from '../ValueFinder';
import Asset from './asset/Asset';
import Settings from './account/Settings';

const AssetManager = () => {
  return (
    <>
      <Header />
      <div className='asset-manager'>
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path={DASHBOARD_URL} element={<Dashboard />} />
          <Route path={ASSETS_URL} element={<Asset />} />
          <Route path={SETTINGS_URL} element={<Settings />} />
          <Route path={VALUE_FINDER_URL} element={<ValueFinder />} />
          <Route path='*' element={<p>No page</p>} />
        </Routes>
      </div>
    </>
  )
}

export default AssetManager;