import React, { useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { AuthContext } from '../../context/AuthContext';

export const summaryColumns = [
  { field: 'name', headerName: 'Asset Name', flex: 1.5, minWidth: 200 },
  {
    field: 'fairMarketValue',
    headerName: 'Fair Market Value',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 80,
    type: 'number',
    valueFormatter: (params) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(params);
    }
  },
  {
    field: 'bookValue',
    headerName: 'Book Value',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 80,
    type: 'number',
    valueFormatter: (params) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(params);
    }
  },
  {
    field: 'estimatedDepreciation',
    headerName: 'Estimated Depreciation',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: (params) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(params);
    }
  },
];

const DetailedDataGrid = () => {
  const { user } = useContext(AuthContext);

  return ( // This will become a simplified table basset off AssetTable.js
    <DataGrid
      autoHeight
      rows={user.assets}
      columns={summaryColumns}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      editMode='false'
      pageSizeOptions={[10, 20, 50]}
      disableColumnResize
      density="compact"
      slotProps={{
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            },
          },
        },
      }}
    />
  );
}

export default DetailedDataGrid;