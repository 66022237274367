import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ASSET_MANAGER_URL, SIGN_IN_URL } from '../constants/pathConstants';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';

import SideBar from './SideBar';

import { AuthContext } from '../context/AuthContext';
import { Button } from '@mui/material';

const Offset = styled('div')({ minHeight: 68 }); //avoid overlap from fixed positioning, roughly height of header

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const navigate = useNavigate();

  const toggleSideBar = (newOpen) => () => {
    setSideBarOpen(newOpen);
  };

  const openAccountMenu = (event) => {
    setAccountMenuAnchor(event.currentTarget);
  };

  const closeAccountMenu = () => {
    setAccountMenuAnchor(null);
  };

  const handleSignOut = () => {
    logout();
  }

  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'white', color: 'black', borderBottom: '1px solid lightgray' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleSideBar(true)}
          >
            <MenuIcon />
          </IconButton>
          <SideBar
            sideBarOpen={sideBarOpen}
            onClose={toggleSideBar(false)}
          />
          <Link className='header-link' to={ASSET_MANAGER_URL}>
            <img src={`${process.env.PUBLIC_URL}/assets/DPA.png`} alt="Logo" style={{ height: '60px', width: 'auto', marginRight: '10px' }} />
            <Typography variant="h4" component="div" sx={{ maxHeight: '60px', whiteSpace: 'nowrap' }}>
              Asset Manager
            </Typography>
          </Link>
          <div className='header-div' />
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openAccountMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(accountMenuAnchor)}
              onClose={closeAccountMenu}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 20px 10px' }}>
                <AccountCircle sx={{ height: '50px', width: 'auto' }} />
                  <Typography variant='h6'>{user.firstName} {user.lastName}</Typography>
              </div>
              <MenuItem>
                <Link to={'/asset-manager/settings'} >
                  My Account
                </Link>
              </MenuItem>
              <Divider component='div' sx={{ marginBottom: '7px' }} />
              <Button onClick={handleSignOut}>
                <MenuItem>Sign Out</MenuItem>
              </Button>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
}

export default Header;