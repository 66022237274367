import React, { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DeleteUserService } from '../../services/userService';

const Settings = () => {
  const { user, logout } = useContext(AuthContext);

  const handleClickDeleteAccount = async () => {
    try {
      const response = await DeleteUserService(user.email);
      if (response.ok) {
        logout();
      } else {
        console.error('Failed to delete account');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleClickSave = () => {
    //TODO: check what edits were made, if any then update context and send edit service
  }

  return (
    <Box className='Settings manager-box'>
      <div className='flex-space'>
        <Typography className='title' variant="h5">
          Settings
        </Typography>
        <div>
          <Button
            variant='contained'
            color='success'
            sx={{ height: '30px', marginRight: '20px' }}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </div>
      </div>

      <div className='flex-space'>
        <TextField
          required
          label='First Name'
          defaultValue={user.firstName}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
        <TextField
          required
          label='Last Name'
          defaultValue={user.lastName}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
      </div>

      <div className='flex-space'>
        <TextField
          label='Company'
          defaultValue={user.company}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
        <TextField
          required
          label='Email'
          defaultValue={user.email}
          sx={{ marginBottom: '16px', width: '49.5%' }}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button
          variant='outlined'
          color='error'
          sx={{ height: '30px' }}
          onClick={handleClickDeleteAccount}
        >
          Delete Account
        </Button>
      </div>
    </Box>
  );
}

export default Settings;