import React from 'react';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import DetailedDataGrid from './charts/DetailedDataGrid';
import MarketPerformanceBarChart from './charts/MarketPerformanceBarChart';
import FairMarketValue from './charts/FairMarketValue';
import StatCard from './charts/StatCard';

const data = [
  {
    title: 'Total Asset Value',
    value: '$14,029',
    percentage: '+4.6%',
    interval: 'Past year',
    color: 'green',
    data: [
      0, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
      360, 400, 380, 420, 400, 620, 340, 460, 440, 480, 460, 500, 700, 750,
    ],
  },
  {
    title: 'Total Dept',
    value: '$3,337',
    percentage: '-3%',
    interval: 'Past month',
    color: 'red',
    data: [
      1000, 1000, 1000, 1130, 1130, 1130, 1130, 1130, 1130, 700, 700, 700, 700, 700, 820,
      820, 820, 820, 380, 380, 380, 380, 380, 500, 500, 500, 500, 500, 500, 500,
    ],
  },
  {
    title: 'Total Projected ROI',
    value: '$19,980',
    percentage: '+5.8%',
    interval: 'Past year',
    data: [
      1000, 950, 970, 920, 840, 890, 860, 800, 810, 790, 800, 780, 730, 700, 670, 680,
      660, 630, 600, 580, 540, 520, 500, 490, 440, 440, 410, 380, 360, 330,
    ].reverse(),
  },
  {
    title: 'Asset Count',
    value: '25',
    data: [
      2, 2, 2, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 3, 3,
      3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4,
    ],
  },
];

const Dashboard = () => {
  const [filter, setFilter] = React.useState(0);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box className='Dashboard manager-box'>
      <div className='flex-space'>
        <Typography className='title' variant="h5">
          Dashboard
        </Typography>
        <div>
          <Typography variant="h7">
            Filter by:
          </Typography>
          <Select
            labelId="asset-selector-label"
            value={filter}
            onChange={handleFilterChange}
            sx={{ height: '30px', marginLeft: '10px' }}
          >
            <MenuItem value={0}>- All Assets -</MenuItem>
            <MenuItem value={1}>Equipment1</MenuItem>
            <MenuItem value={2}>Equipment2</MenuItem>
          </Select>
        </div>
      </div>

      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <HighlightedCard />
          </Grid> */}
        <Grid size={{ sm: 12, md: 6 }}>
          <FairMarketValue />
        </Grid>
        <Grid size={{ sm: 12, md: 6 }}>
          <MarketPerformanceBarChart />
        </Grid>
      </Grid>
      <Typography className='title' variant="h6">
        Asset Report
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ sm: 12 }}>
          <DetailedDataGrid />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;