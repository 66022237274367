export const DEMO_USER = {
  firstName: 'Demo', 
  lastName: 'User', 
  company: 'Demo Company', 
  email: 'demo@email.com', 
  assets: [
    {
      id: 1,
      name: 'PlowMaster 5000',
      estimatedDepreciation: 8345.154115,
      bookValue: 212423.35,
      fairMarketValue: 295423.30,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'PlowMaster 5000',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
    },
    {
      id: 2,
      name: 'CropCutter Pro',
      estimatedDepreciation: 5653,
      bookValue: 172240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'CropCutter Pro',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 3,
      name: 'HarvestMate',
      estimatedDepreciation: 3455,
      bookValue: 58240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'HarvestMate',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 4,
      name: 'GrainGator',
      estimatedDepreciation: 112543,
      bookValue: 96240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'GrainGator',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 5,
      name: 'SoilShredder',
      estimatedDepreciation: 3653,
      bookValue: 142240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'SoilShredder',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 6,
      name: 'AgroTiller XL',
      estimatedDepreciation: 106543,
      bookValue: 15240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'AgroTiller XL',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 7,
      name: 'FieldFury',
      estimatedDepreciation: 7853,
      bookValue: 32240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'FieldFury',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 8,
      name: 'SeedSower X',
      estimatedDepreciation: 8563,
      bookValue: 48240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'SeedSower X',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 9,
      name: 'BarnGuard Elite',
      estimatedDepreciation: 4563,
      bookValue: 18240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'BarnGuard Elite',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 10,
      name: 'TractorMax 300',
      estimatedDepreciation: 9863,
      bookValue: 28240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'TractorMax 300',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 11,
      name: 'HayBale Supreme',
      estimatedDepreciation: 6563,
      bookValue: 24240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'HayBale Supreme',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 12,
      name: 'FertilizerForce',
      estimatedDepreciation: 12353,
      bookValue: 38240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'FertilizerForce',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 13,
      name: 'BaleBinder 2.0',
      estimatedDepreciation: 5863,
      bookValue: 13240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'BaleBinder 2.0',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 14,
      name: 'CropSpray King',
      estimatedDepreciation: 7853,
      bookValue: 18240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'CropSpray King',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
    {
      id: 15,
      name: 'SiloSaver',
      estimatedDepreciation: 9563,
      bookValue: 24240,
      type: 'Equipment',
      manufacturer: 'AssetMakers',
      model: 'SiloSaver',
      modelYear: 2019,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      fairMarketValue: 29023.30,
    },
  ]
}