import * as React from 'react';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/Icon';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import FilterIcon from '@mui/icons-material/FilterAlt';

import AddAsset from './AddAsset';
import AssetTable from './AssetTable';

const Asset = () => {
  const [openNewAsset, setOpenNewAsset] = React.useState(false);

  const handleClickNewAsset = () => {
    setOpenNewAsset(true);
  }

  const handleCloseNewAsset = () => {
    setOpenNewAsset(false);
  };

  return (
    <Box className='Assett manager-box'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} >
        <Typography className='title' variant="h5">
          Assets
        </Typography>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '90%', height: '30px', margin: '0 2%', border: '1px solid lightgray' }}
        >
          <IconButton type="button" aria-label="search">
            <SearchIcon sx={{ margin: '0 0 18px 4px' }} />
          </IconButton>
          <InputBase
            sx={{ ml: '12px', flex: 1 }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search assets' }}
          />
          <FilterIcon />
        </Paper>
        <div style={{ height: '20px' }} />
        <Button
          variant='contained'
          color='success'
          onClick={handleClickNewAsset}
          sx={{ height: '30px', whiteSpace: 'nowrap' }}
        >
          Add Asset
        </Button>
      </div>
      <AddAsset open={openNewAsset} handleClose={handleCloseNewAsset} />


      <Box sx={{ width: '100%', maxWidth: { sm: '100%' } }}>
        <Grid container spacing={2} columns={12}>
          <Grid size={{ sm: 12 }}>
            <AssetTable />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Asset;