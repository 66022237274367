import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

export default function MarketPerformanceBarChart() {
  const theme = useTheme();
  const colorPalette = [
    theme.palette.success.light,
    theme.palette.success.dark,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Market Performance
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              +6.8%
            </Typography>
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Market performance for the last 6 months
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'band',
              categoryGapRatio: 0.5,
              data: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov'],
            },
          ]}
          yAxis={[
            {
              min: 10000,
              max: 16000,
            }
          ]}  
          series={[
            {
              id: 'book-value',
              label: 'Total Book Value',
              data: [11933, 12067, 13508, 12837, 13283, 13672, 14029],
              stack: 'A',
            },
            {
              id: 'liquidation',
              label: 'Estimated Liquidation Value',
              data: [13609, 14305, 15222, 14730, 15054, 15567, 15980],
              stack: 'B',
            },
          ]}
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}