import React, { useState, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { ASSET_MANAGER_URL, DASHBOARD_URL, SIGN_UP_URL } from '../../constants/pathConstants';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';

import ForgotPassword from './ForgotPassword';

import { signInService } from '../../services/userService';

import { AuthContext } from '../../context/AuthContext';

const SignIn = () => {
  const { setUser } = useContext(AuthContext);
  const [email, setEmail] = useState({ value: null, error: false, message: '' });
  const [password, setPassword] = useState({ value: null, error: false, message: '' });
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.error || password.error) {
      return;
    }

    if (!validateInputs()) {
      return;
    }

    signInService({ email: email.value, password: password.value })
      .then((response) => response.json())
      .then((data) => {
        if (data === false) {
          setEmail(x => ({ ...x, error: true, message: '' }));
          setPassword(x => ({ ...x, error: true, message: 'Email and password do not match.' }));
        } else {
          setUser(data);
          navigate(ASSET_MANAGER_URL + DASHBOARD_URL);
        }
      })
      .catch((error) => {
        console.error('There has been an error signing in:', error);
      })
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmail(x => ({ ...x, error: true, message: 'Please enter the email address for your account.' }));
      isValid = false;
    } else {
      setEmail(x => ({ ...x, value: email.value, error: false, message: '' }));
    }

    if (!password.value || password.value.length < 1) {
      setPassword(x => ({ ...x, error: true, message: 'Please enter your password.' }));
      isValid = false;
    } else {
      setPassword(x => ({...x, value: password.value, error: false, message: '' }));
    }

    return isValid;
  };

  return (
    <>
      <Stack className='sign-in-container' direction="column" justifyContent="space-between">
        <MuiCard className='sign-in-card' variant="outlined">
          <img src={`${process.env.PUBLIC_URL}/assets/DPA.png`} alt="Logo" style={{ height: '100px', width: '250px', marginLeft: '60px' }} />
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={email.error}
                helperText={email.message}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={email.error ? 'error' : 'primary'}
                sx={{ ariaLabel: 'email' }}
              />
            </FormControl>
            <FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                  component="button"
                  type="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{ alignSelf: 'baseline' }}
                >
                  Forgot your password?
                </Link>
              </Box>
              <TextField
                error={password.error}
                helperText={password.message}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={password.error ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ForgotPassword open={open} handleClose={handleClose} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={validateInputs}
            >
              Sign in
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              Don&apos;t have an account?{' '}
              <span>
                <RouterLink to={SIGN_UP_URL}>
                  Sign up
                </RouterLink>
              </span>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <RouterLink to={'/'}>
              <Button
                fullWidth
                variant="outlined"
              >
                Cancel
              </Button>
            </RouterLink>
          </Box>
        </MuiCard>
      </Stack>
    </>
  );
}

export default SignIn;