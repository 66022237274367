import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { states } from '../../states';
import { AuthContext } from '../../context/AuthContext'; 

const EditAsset = ({ open, handleClose, asset }) => {
  const { user, setUser } = useContext(AuthContext);
  const [type, setType] = React.useState(asset.type);
  const [bookValue, setBookValue] = React.useState(asset.bookValue);
  const [manufacturer, setManufacturer] = React.useState(asset.manufacturer);
  const [model, setModel] = React.useState(asset.model);
  const [modelYear, setModelYear] = React.useState(asset.modelYear);
  const [usage, setUsage] = React.useState(asset.usage);
  const [condition, setCondition] = React.useState(asset.condition);
  const [country, setCountry] = React.useState(asset.country);
  const [state, setState] = React.useState(asset.state);

  
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    if (e.target.value !== 'US') {
      setState(''); 
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          //edit service
          handleClose();
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Edit Asset</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>Edit your asset information</DialogContentText>

        <TextField
          select
          required
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          fullWidth
        >
          <MenuItem value="Equipment">Equipment</MenuItem>
          <MenuItem value="Vehicle">Vehicle</MenuItem>
        </TextField>

        <TextField
          required
          label="Initial Book Value"
          type="number"
          value={bookValue}
          onChange={(e) => setBookValue(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Manufacturer"
          value={manufacturer}
          onChange={(e) => setManufacturer(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Model Year"
          type="number"
          value={modelYear}
          onChange={(e) => setModelYear(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Usage (in hours or miles)"
          type="number"
          value={usage}
          onChange={(e) => setUsage(e.target.value)}
          fullWidth
        />

        <TextField
          select
          required
          label="Condition"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          fullWidth
        >
          <MenuItem value="Excellent">Excellent</MenuItem>
          <MenuItem value="Very Good">Very Good</MenuItem>
          <MenuItem value="Good">Good</MenuItem>
          <MenuItem value="Fair">Fair</MenuItem>
          <MenuItem value="Poor">Poor</MenuItem>
        </TextField>

        <TextField
          select
          required
          label="Country"
          value={country}
          onChange={handleCountryChange}
          fullWidth
        >
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="MX">Mexico</MenuItem>
        </TextField>

        {country === 'US' && (
          <TextField
            select
            required
            label="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
          >
            <MenuItem value="">- Select -</MenuItem>
            {states.map((state) => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </DialogContent>

      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button color='success' onClick={handleClose}>Cancel</Button>
        <Button color='success' variant="contained" type="submit">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditAsset.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //TODO: requirement for asset
};

export default EditAsset;
