import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

const getInitialState = () => {
  const currentUser = sessionStorage.getItem("currentUser");
  return currentUser ? JSON.parse(currentUser) : null;
}

const AuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState(getInitialState);

  useEffect(() => {
      sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
  }, [currentUser])

  const setUser = (user) => {
    if(user && user.fullName) {  
      [user.firstName, user.lastName] = user.fullName.split(' ');
    }
    setCurrentUser(user);
  }

  const logout = () => {
    setCurrentUser(null);
  }

  const addAsset = (newAsset) => {
    setUser((prevUser) => ({
      ...prevUser,
      assets: prevUser.assets ? [...prevUser.assets, { ...newAsset, id: prevUser.assets.length + 1 }] : [{ ...newAsset, id: 1 }],
    }));
  };
  

  return (
      <AuthContext.Provider 
        value={{user: currentUser, setUser, logout, addAsset}}>
          { children }
      </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }