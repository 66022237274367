import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import { SIGN_IN_URL, SIGN_UP_URL, ASSET_MANAGER_URL, DASHBOARD_URL } from "../constants/pathConstants";
import { DEMO_USER } from "../constants/demoUserConstants";

import { AuthContext } from "../context/AuthContext";

const Welcome = () => {
  const { setUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleRunDemo = () => {
    setUser(DEMO_USER);
    navigate(ASSET_MANAGER_URL + DASHBOARD_URL);
  }

  return (
    <div>
      <h1>HELLO!! Welcome to DPA Auctions SUPER AWESOME Asset Management system!!!</h1>
      <br />
      <Link to={SIGN_IN_URL}>Sign In :D</Link>
      <br /><br />
      <Link to={SIGN_UP_URL}>Sign Up :o</Link>
      <br /><br />
      <button onClick={handleRunDemo}>View Dashboard and Asset Management through a Demo User</button>
    </div>
  );
}

export default Welcome;