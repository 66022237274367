import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

function timeData() {
  const days = ['Dec2', 'Dec1', 'Jan2', 'Jan1', 'Jan', 'Feb2', 'Feb1', 'Mar2', 'Mar1', 'Mar', 'Apr2', 'Apr1', 'May2', 'May1', 'May', 'Jun2', 'Jun1', 'Jul2', 'Jul1', 'Jul', 'Aug2', 'Aug1', 'Sep2', 'Sep1', 'Sep', 'Oct2', 'Oct1', 'Nov2', 'Nov1', 'Nov'];
  return days;
}

export default function FairMarketValue() {
  const theme = useTheme();
  const data = timeData();

  const colorPalette = [
    theme.palette.success.main,
    theme.palette.error.main,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Fair Market Value
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              +$510.28
            </Typography>
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Estimated liquidation price per month for the last year
          </Typography>
        </Stack>
        <LineChart
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'point',
              data,
              tickInterval: (index, i) => (i + 1) % 5 === 0,
            },
          ]}
          series={[
            {
              id: 'book',
              label: 'Book',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                30, 90, 60, 120, 150, 180, 240, 210, 270, 300, 180, 330,
                360, 390, 420, 450, 390, 480, 510, 540, 480, 570, 600,
                630, 660, 690, 720, 750, 780, 910,
              ],
            },
            {
              id: 'market',
              label: 'Market',
              showMark: false,
              curve: 'linear',
              stack: 'market',
              area: false,
              stackOrder: 'ascending',
              data: [
                30, 90, 60, 120, 150, 180, 240, 210, 270, 300, 180, 330,
                360, 390, 420, 450, 390, 480, 510, 540, 480, 570, 600,
                630, 660, 690, 650, 750, 880, 810,
              ].reverse(),
            },
          ]}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          sx={{
            '& .MuiAreaElement-series-book': {
              fill: "url('#book')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color={theme.palette.success.light} id="book" />
        </LineChart>
      </CardContent>
    </Card>
  );
}