import * as React from 'react';
import PropTypes from 'prop-types'; //add requirements to the row prop for the attributes used

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { areaElementClasses } from '@mui/x-charts/LineChart';
import { useTheme } from '@mui/material/styles';

import formatCurrencyUSD from '../../internals/formatCurrency';
import EditAsset from './EditAsset';

const graphData = [
  10, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
  360, 400, 380, 420, 400, 620, 340, 460, 440, 480, 460, 500, 700, 750,
];

const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.3} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const AssetTableRow = ({ row }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [openEditAsset, setOpenEditAsset] = React.useState(false);

  const handleClickEdit = () => {
    setOpenEditAsset(true);
  }

  const handleCloseEditAsset = () => {
    setOpenEditAsset(false);
  };

  const chartColor = theme.palette.error.light;

  return (
    <>
      <TableRow onClick={() => setExpanded(!expanded)} sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}>
        <TableCell sx={{ paddingRight: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ padding: '5px 0' }}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.bookValue)}</TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.estimatedDepreciation)}</TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.fairMarketValue)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0, display: 'flex' }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ flexGrow: 1 }}>
                Details (Image, Graph of depreciation, some sort of asset report)
              </Typography>
              <IconButton
                hover
                title='Edit'
                aria-label="edit asset"
                size="medium"
                color='success'
                onClick={handleClickEdit}
              >
                <EditSharpIcon />
              </IconButton>
              <IconButton
                title='Delete'
                aria-label="delete asset"
                size="medium"
              >
                <DeleteSharpIcon />
              </IconButton>
            </Box>

            <Box sx={{ margin: 0, display: 'flex' }}>
              <img width={200} height={100} style={{ marginBottom: '10px' }} />
              <Box sx={{ width: '50%', height: 100, marginLeft: '5%' }}>
                <SparkLineChart
                  colors={[chartColor]}
                  data={graphData}
                  area
                  showHighlight
                  showTooltip
                  xAxis={{
                    scaleType: 'band',
                    data: days,
                  }}
                  sx={{
                    [`& .${areaElementClasses.root}`]: {
                      fill: `url(#area-gradient-Details)`,
                    },
                  }}
                >
                  <AreaGradient color={chartColor} id={`area-gradient-Details`} />
                </SparkLineChart>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
        <EditAsset open={openEditAsset} handleClose={handleCloseEditAsset} asset={row} />
      </TableRow>
    </>
  );
}

export default AssetTableRow;