import { 
  BACKEND_BASE_URL,
  CREATE_ASSET
} from "../constants/apiConstants";

export async function createAssetService(data) {
  const response = await fetch(BACKEND_BASE_URL + CREATE_ASSET, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response; 
}

export async function getAssetTestService(data) {
  const response = await fetch(BACKEND_BASE_URL + '/getassets', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response; 
}